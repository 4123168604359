import React from 'react';

export default function ToPV() {
    return (
        <></>
    )
}

//  redirect to /research/polycythemia-vera
//  no luck using gatsby-node
export const Head = () => (
    <>
        <meta name="robots" content="noindex"></meta>
        <meta http-equiv="refresh" content="0;url=/research/polycythemia-vera"/>
    </>
)
